import {initializeApp} from 'firebase/app'
import {ref, getDatabase, child} from 'firebase/database'

/**
 * Parse the firebase environment variable and return it.
 * See set-var.bash and firebase.(development|production).json file
 */
export function GetFirebaseConfig() {
    return JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG)
}

export function InitializeFirebaseApp() {
    initializeApp(GetFirebaseConfig())
}

/**
 * Create a new firebase database reference.
 * A path can be prepended to all references for example /childpath -> /test/childpath
 * @param path Realtime databse child path
 */
export function DbRef(path?: string) {
    const r = ref(getDatabase(), process.env.REACT_APP_FIREBASE_DATABASE_ROOT)
    return path === undefined ? r : child(r, path)
}