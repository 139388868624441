/**
 * A simple implementation for event handlers.
 * Consider using any other method such as using Redux or React Context before using this method
 */

export default class Signal {
    private readonly callbacks: (() => any)[] = []

    add(callback: any) {
        this.callbacks.push(callback)
    }

    remove(callback: any) {
        const index = this.callbacks.indexOf(callback)

        if (index > -1) {
            this.callbacks.splice(index, 1)
        }
    }

    dispatch() {
        const callbacks = this.callbacks.slice(0)
        callbacks.forEach((callback) => {
            try {
                callback()
            } catch (error) {
                console.log(error)
            }
        })
    }
}
